import { createRouter, createWebHistory } from "vue-router"
import { workspaceRoutes } from "./workspaceRoutes"

const routes = [
  ...workspaceRoutes,
  {
    path: '/',
    name: 'landing-page',
    component: () => import('@/views/common-main.vue'),
  },
  {
    path: '/common/members',
    name: 'members',
    component: () => import('@/views/manage-members-tab-holder/manage-members-tab-holder.vue'),
  },

  {
    path: '/common/certifications',
    name: 'certifications',
    component: () => import('@/views/certifications/certifications.vue'),
  },
  {
    path: '/common/plan',
    name: 'plan',
    component: () => import('@/views/plans/plans.vue'),
  },
  {
    path: '/common/payment-plan',
    name: 'payment-plan',
    component: () => import('@/components/choosePlans/payment-plans/payment-plan.vue'),
  },
  {
    path: '/common/payment-details',
    name: 'payment-details',
    component: () => import('@/components/choosePlans/payment-details/payment-details.vue'),
  },
  {
    path: '/common/payment-process',
    name: 'payment-process',
    component: () => import('@/components/choosePlans/payment-process/payment-process.vue'),
  },
]
const router = createRouter({
  mode: "history",
  base: process.env.BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "dronos-router-link-exact-active",
  linkExactActiveClass: "dronos-router-link-exact-active",
})

router.afterEach(() => {
  const appLoading = document.getElementById("loading-bg")
  if (appLoading) {
    appLoading.style.display = "none"
  }
})

export default router
