// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap);"]);
// Module
exports.push([module.id, "html body {\n  font-size: 1rem;\n  background-color: #ffffff;\n}\n\n:root {\n  font-size: 12px !important;\n}\n\n.App-module_common-container_2W13q {\n  display: flex;\n  overflow: hidden;\n  background-color: #14171f;\n  position: absolute;\n  z-index: 1;\n  height: calc(100% - var(--footer-height, 0px));\n  width: calc(100% - var(--sidebar-width, 0px));\n  left: var(--sidebar-width, 0px);\n}\n\n.App-module_router-div_3Cwyl {\n  margin-left: 164px;\n  width: calc(100% - 164px);\n  margin-top: 4rem;\n}", ""]);
// Exports
exports.locals = {
	"common-container": "App-module_common-container_2W13q",
	"router-div": "App-module_router-div_3Cwyl"
};
module.exports = exports;
