import { createApp, h } from "vue";
import singleSpaVue from "single-spa-vue";
import { QueryClient, VueQueryPlugin } from "@tanstack/vue-query";
import App from "./App.vue";

import router from "./router";

import "@/scss/core.scss";
import '@/scss/global.scss'; 
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-select/dist/vue-select.css";
import './config-hsf'

import permissionDirective from "./user-permissions/user-permission.directive";

if(process.env.NODE_ENV == 'production') {
  window.console.error = () => {};
}



const myClient = new QueryClient();
const vueQueryPluginOptions = {
  queryClient: myClient,
};

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, { which: this.mainApp });
    },
  },
  handleInstance: (app1) => {
    app1.use(router);
    app1.use(VueQueryPlugin, vueQueryPluginOptions);
    app1.directive('PermissionGuard',permissionDirective)
  },
});

export const { bootstrap } = vueLifecycles;
export function mount(props) {
  return vueLifecycles.mount(props);
}
export const unmount = vueLifecycles.unmount;
