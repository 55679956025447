// eslint-disable-next-line import/prefer-default-export
export const workspaceRoutes = [
  {
    path: "/common/member-details/:id",
    name: "member-details",
    component: () => import("@/views/workspace/member-details-page/member-details.vue"),
  },
  {
    path: "/common",
    name: "overview",
    component: () => import("@/components/overview/overview.vue"),
  },
  {
    path: "/common/about",
    name: "about",
    component: () => import("@/views/workspace/about-workspace/about-workspace.vue"),
  },
  {
    path: "/common/my-account",
    name: "Account Page",
    component: () => import("@/components/account-page/account-page.vue"),
  },
];
