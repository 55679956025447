import { isGranted } from "./user-permission.service";

export default {
 mounted(el, binding) {
  if (!isGranted(binding.value)) {
   el.parentNode.removeChild(el);
  }
 },
 updated(el, binding) {
  if (el.parentNode) {
   if (!isGranted(binding.value)) {
    el.parentNode.removeChild(el);
   } else if (!el.parentNode) {
    const parent = binding.instance.$refs[binding.name];
    if (parent) {
     parent.appendChild(el);
    }
   }
  }
 },
};
