<template>
  <div id="app">
    <div :class="styles['common-container']">
      <div :class="styles['router-div']">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import styles from './App.module.scss'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  props: {
    which: String,
  },
  components: {
  },
  mounted() {
  },
  data() {
    return {
      styles
    }
  }
})
</script>
