import { state } from "@app/utility";

export const getUserPermissions = () => {
  const workspaceInfo = state.getActiveWorkspaceEntity();
  return workspaceInfo?.role.permissions ?? [];
};
export const isGranted = (permissionList = []) => {
  const userPermissions = getUserPermissions();
  return userPermissions.some((item) => permissionList.includes(item));
};
